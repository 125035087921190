import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import './Swap/Navbar.css';
import { ethers } from 'ethers';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { useWeb3Modal, useWeb3ModalSigner } from '@web3modal/ethers5/react';
import { abi as lockContractABI } from './ContractABI'; // Adjust the path as needed
import { abi as tokenABI } from './faib2ABI'; // Adjust the path as needed
import Modal from './Swap/Modal'; // Adjust the path according to your file structure
import SwapComponent from './Swap/SwapComponent';
import { abi as DeflationarySwapABI } from './Swap/DeflationarySwapABI'; // Adjust the path as needed
import logoImage from './AIBOT_logo.png';
import Lottery from './lottery/lotterypage'; // Adjust the path according to your project structure
import SwapContainer from './Swap/swappage'; // Adjust the path according to your project structure
import SwapContainer1 from './Swap1/swappage'; // Adjust the path according to your project structure
import WalletSelectorModal from './Swap/WalletSelectorModal';
import BottomMenuBar from './BottomMenuBar'; // Import the BottomMenuBar component
import Presale from './components/presaleInterface';

import Staking from './staking/stakingpage';
import MainExplorer from './token_explorer/top-tokens-by-market-cap/MainExplorer';
import MainExplorer_tv from './token_explorer/top-tokens-by-tranding-volume/MainExplorer_tv';
import Main_Token_Explorer2 from './token_explorer/token_explorer/Main_Token_Explorer2';

import TokenBalances from './Swap/TokenBalances';
import Sidebar from './token_explorer/top-tokens-by-market-cap/sidebar';
import TokenExplorer from './token_explorer/top-tokens-by-market-cap/MainExplorer';

const contractAddress = '0x7d0ca4e0FCe3A5727962D3c146d16030fbe4e2f1';
const contractAddressFAIB2 = '0xaE4c637fb9cb5C151549768a787CCa54c044BdcA';
// Web3Modal configuration
const projectId = 'd1c2210ee614f7c716a2e89e2aa68b50'; // Replace with your WalletConnect project ID

const bscMainnet = {
  chainId: 56, // BSC Mainnet Chain ID
  name: 'BSC Mainnet',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.binance.org/' // BSC Mainnet RPC URL
};

const metadata = {
  name: 'Floki Swap',
  description: 'FAIB2 SWAP',
  url: 'https://dex.flokiaibot.com',
  icons: ['https://flokiaibot.com/static/media/AIBOT_logo.0bf99934a1aec060e3ee.png']
};

// Define the restricted addresses
const restrictedAddresses = [

  '0x3c8d6b5B86a92Bb5e97E08e4A028907D7322aD55',
  '0xC78d45e852f8d0C331CAfbaf4287788C7068eeD6',
 
].map(address => address.toLowerCase());

export default function ConnectButton() {
  const web3Modal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [bscMainnet],
    projectId,
    themeMode: 'dark',
    themeVariables: {
      '--w3m-z-index': 1000000
    }
  });

  const { open } = useWeb3Modal(web3Modal);
  const { signer } = useWeb3ModalSigner();
  const [currentUserAddress, setCurrentUserAddress] = useState('');
  const [walletBalance, setWalletBalance] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [customTokenBalance, setCustomTokenBalance] = useState(0);
  const [scrollItems, setScrollItems] = useState([]);
  const [userAddress, setUserAddress] = useState('');
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [error, setError] = useState(""); 
  const [isRestricted, setIsRestricted] = useState(false); 

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  // Function to check if the address is restricted
  const isAddressRestricted = (address) => {
    return restrictedAddresses.includes(address.toLowerCase());
  };

  useEffect(() => {
    const fetchCurrentUserAddress = async () => {
      if (signer) {
        const newAddress = (await signer.getAddress()).toLowerCase();

        if (isAddressRestricted(newAddress)) {
          setIsRestricted(true);
          setError("Access Denied: Your wallet address is restricted from using this DApp.");
          await web3Modal.clearCachedProvider(); 
          return;
        }

        setIsRestricted(false); 
        setCurrentUserAddress(newAddress);

        fetchWalletBalance();
        fetchTokenBalance();
      }
    };

    fetchCurrentUserAddress();
  }, [signer]);

  const bscMainnetChainId = '0x38'; // Hexadecimal chain ID for BSC Mainnet
  const [currentChainId, setCurrentChainId] = useState('');

  const switchToBscMainnet = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: bscMainnetChainId }],
      });
    } catch (error) {
      console.error('Error switching to BSC Mainnet:', error);
    }
  };

  useEffect(() => {
    if (signer) {
      signer.getChainId().then(chainId => {
        setCurrentChainId(chainId.toString(16));
        if (chainId.toString(16) !== bscMainnetChainId) {
          switchToBscMainnet();
        }
      });
    }
  }, [signer]);

  const fetchWalletBalance = async () => {
    if (signer) {
      try {
        const balance = await signer.getBalance();
        const etherBalance = parseFloat(ethers.utils.formatEther(balance)).toFixed(4);
        setWalletBalance(etherBalance);
      } catch (error) {
        console.error('Error fetching wallet balance:', error);
      }
    } else {
      console.log("Signer not found"); 
    }
  };

  const fetchTokenBalance = async () => {
    if (signer && currentUserAddress) {
      const tokenContract = new ethers.Contract(contractAddressFAIB2, tokenABI, signer);
      try {
        const tokenBalanceBigNumber = await tokenContract.balanceOf(currentUserAddress);
        const tokenBalance = ethers.utils.formatUnits(tokenBalanceBigNumber, 18);
        setCustomTokenBalance(tokenBalance);
      } catch (error) {
        console.error('Error fetching custom token balance:', error);
      }
    }
  };

  useEffect(() => {
    const subscribeToAccountChanges = () => {
      if (window.ethereum) {
        window.ethereum.on('accountsChanged', async (accounts) => {
          if (accounts.length > 0) {
            setCurrentUserAddress(accounts[0].toLowerCase());
          } else {
            setCurrentUserAddress('');
          }
        });
      }
    };

    subscribeToAccountChanges();

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', subscribeToAccountChanges);
      }
    };
  }, []);

  const fetchTokenPrice = async () => {
    try {
      const response = await fetch('https://api.geckoterminal.com/api/v2/simple/networks/bsc/token_price/0xaE4c637fb9cb5C151549768a787CCa54c044BdcA', {
        method: 'GET',
        headers: {
          'accept': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const tokenPrice = data.data.attributes.token_prices["0xae4c637fb9cb5c151549768a787cca54c044bdca"];
      return parseFloat(tokenPrice); 
    } catch (error) {
      console.error('Failed to fetch token price:', error);
      return 0; 
    }
  };

  useEffect(() => {
    fetchTokenPrice().then(price => {
      console.log("Fetched token price:", price);
      setTokenPrice(parseFloat(price)); 
    });
  }, []);

  if (isRestricted) {
    return (
      <div className="error-message">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <Router>
      <Sidebar
        isSidebarVisible={isSidebarVisible}
        toggleSidebar={toggleSidebar}
        currentUserAddress={currentUserAddress}
        connectWallet={() => open()}
        walletBalance={walletBalance}
        customTokenBalance={customTokenBalance}
        tokenPrice={tokenPrice}
      />

      <Routes>
        <Route path="/" element={<Navigate replace to="/TokenExplorer/0xae4c637fb9cb5c151549768a787cca54c044bdca" />} />
        <Route path='/wallet' element={<WalletSelectorModal signer={signer} walletBalance={walletBalance} onSwapComplete={fetchWalletBalance} isSidebarVisible={isSidebarVisible}/>} />
        <Route path="/lottery" element={<Lottery signer={signer} isSidebarVisible={isSidebarVisible}/>} />
        <Route path="/staking" element={<Staking isSidebarVisible={isSidebarVisible}/>} />
        <Route path="/TokenExplorer_mc" element={<MainExplorer isSidebarVisible={isSidebarVisible} />} />
        <Route path="/TokenExplorer_tv" element={<MainExplorer_tv isSidebarVisible={isSidebarVisible} />} />
        <Route path="/TokenExplorer" element={<Main_Token_Explorer2 signer={signer} walletBalance={walletBalance} onSwapComplete={fetchWalletBalance} isSidebarVisible={isSidebarVisible} />} />
        <Route path="/TokenExplorer/:address" element={<Main_Token_Explorer2 signer={signer} walletBalance={walletBalance} onSwapComplete={fetchWalletBalance} isSidebarVisible={isSidebarVisible} />} />
        <Route path="/Presale" element={<Presale isSidebarVisible={isSidebarVisible}/>} />
      </Routes>

      <BottomMenuBar
        isSidebarVisible={isSidebarVisible}
        toggleSidebar={toggleSidebar}
        currentUserAddress={currentUserAddress}
        connectWallet={() => open()}
        walletBalance={walletBalance}
        customTokenBalance={customTokenBalance}
        tokenPrice={tokenPrice}
      />
    </Router>
  );
}
