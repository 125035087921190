import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import logoImage from '../images/AIBOT_logo.png';
import { ethers } from 'ethers';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';
import { useWeb3Modal, useWeb3ModalSigner } from '@web3modal/ethers5/react';
import BottomNav from './BottomNav'; // Import the BottomNav component


const Sidebar = ({
    isSidebarVisible,
    toggleSidebar,
    currentUserAddress,
    connectWallet,
    walletBalance,
    customTokenBalance,
    tokenPrice
  }) => {


    
    return (
        <div> 
      <div className={`sidebar ${isSidebarVisible ? 'visible' : 'hidden'}`}>
        <img src={logoImage} alt="Logo" className="sidebar-logo" />
      <div className="wallet-info">
  {currentUserAddress && (
    <div className="wallet-address">
      Wallet: {`${currentUserAddress.substring(0, 6)}...${currentUserAddress.substring(currentUserAddress.length - 4)}`}
      <div className="wallet-balances-tooltip">
        <div className="wallet-balance">
          BNB Balance: <strong>{walletBalance} BNB</strong>
        </div>
        <div className="wallet-balance">
        FAIB2 Balance: <strong>{parseFloat(customTokenBalance).toFixed(4)} FAIB2</strong>
        </div>
        <div className="wallet-balance">
          FAIB2 Value: <strong>$ {customTokenBalance*tokenPrice.toFixed(20)}</strong>
        </div>
        <div className="wallet-balance">
          FAIB2 Price: <strong>{tokenPrice.toFixed(20)}</strong>
        </div>

        



      </div>
    </div>
  )}
           <button className="connect-wallet-btn" onClick={connectWallet}>
  {currentUserAddress ? 'Connected' : 'Connect Wallet'}
</button>

          </div>
          <Link to="/TokenExplorer/0xae4c637fb9cb5c151549768a787cca54c044bdca">Token Explorer</Link>
          <Link to="/wallet" className="sidebar-link">Wallet</Link>
          <Link to="/lottery" className="sidebar-link">Lottery</Link>
          <Link to="/staking" className="sidebar-link">Staking</Link>


      <Link to="/TokenExplorer_mc">Top Tokens By Market Cap</Link>
      <Link to="/TokenExplorer_tv">Top Tokens On The Move</Link>
      <Link to="/Presale" className="sidebar-link">Presale</Link>




        
      
   
      
    </div>
    <button className="sidebar-toggle" onClick={toggleSidebar}>
        {isSidebarVisible ? 'Hide' : 'Show Menu'}
      </button>

    
    </div>
  );
};

export default Sidebar;
