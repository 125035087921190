import React from 'react';
import { NavLink } from 'react-router-dom';
import './BottomMenuBar.css'; // Ensure to create this CSS file

const BottomMenuBar = ({
    isSidebarVisible,
    toggleSidebar,
    currentUserAddress,
    connectWallet,
    walletBalance,
    customTokenBalance,
    tokenPrice
  }) => {
    return (
    <div className="bottom-menu-bar">
      <NavLink to="/wallet" className={({ isActive }) => (isActive ? 'active' : '')}>
        Wallet
      </NavLink>
      <NavLink to="/staking" className={({ isActive }) => (isActive ? 'active' : '')}>
        Staking
      </NavLink>
      <NavLink to="/lottery" className={({ isActive }) => (isActive ? 'active' : '')}>
        Lottery
      </NavLink>
      <NavLink to="/TokenExplorer/0xae4c637fb9cb5c151549768a787cca54c044bdca" className={({ isActive }) => (isActive ? 'active' : '')}>
        Explorer
      </NavLink>
      <button className="connect-wallet-btn" onClick={connectWallet}>
  {currentUserAddress ? 'Connected' : 'Connect Wallet'}
</button>
    </div>
  );
};

export default BottomMenuBar;
